export const SET_WAITING_LOG_IN = 'SET_WAITING_LOG_IN';

interface SetWaitingLogIn {
  type: typeof SET_WAITING_LOG_IN;
  payload: boolean;
}

export type InputInfosActionType = SetWaitingLogIn;

const setWaitingLogIn = (waitingLogIn: boolean): SetWaitingLogIn => {
  return {type: SET_WAITING_LOG_IN, payload: waitingLogIn};
};

export const GenericActions = {
  setWaitingLogIn,
};
